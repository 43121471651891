// @ts-nocheck
import React, { useState, createContext, useContext, useCallback, useEffect, useMemo } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useHistory, useRouteMatch, useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import inRange from 'lodash/inRange';
import compareDesc from 'date-fns/compareDesc';
import { Dispatch } from 'state/types';
import { SandboxActionName } from 'state/sandbox/types';
import { RootState } from 'state/root';
import config from 'config';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { getLatestSandboxesByHash, getSandboxTaskByHashAndId } from 'state/sandbox/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styled from '@emotion/styled';
import styles from 'views/styles';
import tenant from 'tenants';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import PanelLoader from 'views/components/Loader/PanelLoader';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import SidebarActions from 'views/components/layout/Sidebar/SidebarActions';
import SidebarDetail from 'views/components/layout/Sidebar/SidebarDetail';
import PivotingFilter from 'views/components/Pivoting/Filter';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { closeModal, openModal } from 'state/modal/actions';
import { showNotification } from 'state/notification/actions';
import { getAccountContext } from 'state/account/actions';
import { ContextAccount, readContextAccount } from 'state/auth/selectors';
import SidebarLoading from 'views/components/layout/Sidebar/SidebarLoading';
import joinUrl from 'utils/joinUrl';
import { isDateBefore, toISODateTime } from 'utils/date/date';
import { SandboxInstanceStatus, SandboxProviders, Sandbox, SandboxReport } from 'models/Sandbox';
import { useAuth } from 'views/components/providers/AuthProvider';
import useHasFeature from 'hooks/useHasFeature';
import Icon from 'views/components/Icon';
import NetworkTab, { getNetworkData } from './NetworkTab';
import JSONTab from './JSONTab';
import DroppedTab, { getDroppedFilesData } from './DroppedTab';
import AnalysisTab, { getAnalysisData } from './AnalysisTab';
import ExtractedConfigTab, { getExtractedConfigData } from './ExtractedConfigTab';
import ArtifactDownloadTab from './ArtifactDownloadTab';
import HttpRequestsTab, { getHttpData } from './HttpRequestsTab';
import VideoTab from './VideoTab';
import ScreenshotTab from './ScreenshotTab';
import EmptySandboxing from './SandboxPanel/SandboxTabs/EmptySandboxing';
import PaginatedTabs from 'views/components/PaginatedTabs/PaginatedTabs';
import { parseSandboxURL, parseScanPageURL } from 'views/url';
import { isPivotingEnabled } from 'tenants/utils';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';
import Button from '@material-ui/core/Button';
import ErrorPageContent from 'views/components/error/ErrorPageContent';

const getSortedSandboxList = (list, sandboxId) => {
  const cleanedUpSandboxes = list.filter(
    (item) => !!item && (item.status === SandboxInstanceStatus.SUCCEEDED || item.id === sandboxId)
  );

  const groupBySandbox = groupBy(cleanedUpSandboxes, (item) => item.sandbox);

  const sortedBySandboxWithLatest = reduce(
    groupBySandbox,
    (acc, value, key) => {
      const ordered = value.sort((a, b) => {
        return compareDesc(new Date(a.created), new Date(b.created));
      });

      return { ...acc, [key]: ordered };
    },
    {}
  );
  return sortedBySandboxWithLatest;
};

const getEffectiveURL = (report: Sandbox<SandboxReport>['report'] | undefined, target: string) => {
  if (!report) {
    return null;
  }

  const requests = (report.requests || [])
    .map((request) => (request || {}).http_response || [])
    .filter((request) => request.length > 0)
    .flat();

  const getByAllowOrigin = () => {
    const [data] = requests
      .filter((request) => request.status === '200' || request.status === '201')
      .filter((request) =>
        request.headers.some(
          (header) => header.startsWith('access-control-allow-origin') && !header.includes(': *')
        )
      );
    return data
      ? data.headers
          .filter((header) => header.startsWith('access-control-allow-origin'))[0]
          .split(': ')[1]
      : null;
  };

  const removeUrlProtocol = (url) => url.replace(/(^\w+:|^)\/\//, '').replace('www.', '');

  const getByLocation = () => {
    const [data] = requests
      .filter((request) => request.status === '301' || request.status === '302')
      .filter((request) =>
        request.headers.some(
          (header) => header.startsWith('location') && !header.endsWith(removeUrlProtocol(target))
        )
      );
    const location = data
      ? data.headers.filter((header) => header.startsWith('location'))[0].split(': ')[1]
      : null;

    if (location && !location.startsWith('http')) {
      return target.endsWith('/') ? target.slice(0, -1) + location : target + location;
    }

    return location;
  };

  return getByLocation(report) ?? getByAllowOrigin(report);
};

const UnAuthSandboxPanel = () => {
  return <EmptySandboxing type='detailsPage' forbidden='notLoggedIn' />;
};

const UnAuthSidebarCard = () => {
  const { classes } = useStyles();

  return (
    <Container>
      <Card css={[classes.root]}>
        <h1 css={{ ...classes.title, textTransform: 'none' }}>
          Log in or create an account to access sandbox data.
        </h1>
        <div css={classes.subDetail}>
          <div style={{ filter: 'blur(4px)' }}>{toISODateTime(new Date())}</div>
        </div>
        <VerticalBar />
        <DetailsContainer>
          <SidebarDetail blurred label='Sandbox Id' value='33969787630527805' monospace={true} />
          <SidebarDetail
            blurred
            label='SHA-256'
            value='f0c542cc2a57d6f3de08e05e2b35b87f5e68f4e011821633185ce632a6710cda'
            monospace={true}
          />
          <SidebarDetail blurred label='Family' value='Unknown' />
        </DetailsContainer>
      </Card>
      <SandboxSidebarActions disabledHistory disabledSandbox disabledPivot disabledShare />
    </Container>
  );
};

const messages = {
  resandboxingSuccess:
    'The artifact is queued for sandboxing. You can monitor the status on the Sandbox → My Sandbox tab.',
};

const SandboxSidebarActions = ({
  selectedSandbox = {},
  disabledPivot = false,
  disabledShare = false,
  disabledSandbox = false,
  disabledHistory = false,
}: {
  selectedSandbox: Sandbox<SandboxReport>;
  disabledPivot: boolean;
  disabledShare: boolean;
  disabledSandbox?: boolean;
  disabledHistory?: boolean;
}) => {
  const history = useHistory();
  const isSandboxDisabledInTenant = tenant.disabledPages?.includes('sandbox');
  const dispatch = useDispatch();
  const {
    active: isPivotActive,
    onToggleActive: onTogglePivotActive,
    setArtifactId,
  } = usePivoting();
  const match = useRouteMatch<{ artifactType: string; sha256: string }>();
  const { isAuthenticated } = useAuth();
  const { hasPermission: hasSandboxRequestFeature } = useHasFeature('sandbox_request');
  const isCommunityPlan = useIsCommunityPlan();

  const noSandboxFeature = !isAuthenticated || !hasSandboxRequestFeature;
  const noReportFeature = !isAuthenticated || isCommunityPlan;

  const resandboxing = () => {
    if (isSandboxDisabledInTenant) {
      return null;
    } else {
      if (noSandboxFeature) {
        return history.push('/pricing/enterprise');
      }

      dispatch(
        openModal('SUBMIT_TO_SANDBOX_MODAL', {
          onSubmit: () => {
            dispatch(
              showNotification({
                status: 'success',
                message: messages.resandboxingSuccess,
              })
            );
            dispatch(closeModal());
          },
          instanceId: selectedSandbox.instanceId,
          defaultProvider: selectedSandbox.config?.vm?.name
            ? selectedSandbox.sandbox + ' - ' + selectedSandbox.config.vm.name
            : selectedSandbox.sandbox,
          defaultType: match?.params.artifactType === 'url' ? 'url' : 'hash',
          defaultHash: match?.params?.sha256,
          isResandbox: true,
        })
      );
    }
  };

  useEffect(() => {
    if (match.params.sha256) {
      setArtifactId(match.params.sha256);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.sha256]);

  return (
    <SidebarActions
      className='h-mt-grid'
      items={[
        {
          icon: 'sandbox',
          name: 'Re-Sandbox',
          onClick: resandboxing,
          dataCy: 'sandboxingBtn',
          disabled: !!disabledSandbox || noSandboxFeature,
          warning: noSandboxFeature,
          tooltipTitle:
            !Boolean(disabledSandbox) && (noSandboxFeature || isSandboxDisabledInTenant) ? (
              <>
                Sandboxing is <span style={{ fontWeight: 'bold' }}>unavailable</span> for current
                plan.
              </>
            ) : (
              ''
            ),
        },
        {
          icon: 'share',
          name: 'Share',
          shareUrl: `${config.url}${match.url}`,
          dataCy: 'shareSandboxBtn',
          disabled: disabledShare,
        },
        {
          icon: 'pivot',
          name: 'Pivot',
          dataCy: 'sandboxPivotBtn',
          onClick: () => {
            onTogglePivotActive(match.params.sha256);
            setTimeout(() => {
              if (!isPivotActive) {
                document.getElementById('pivot-search')?.scrollIntoView({
                  behavior: 'smooth',
                });
              }
            }, 100);
          },
          iconFilled: isPivotActive,
          isShown: isPivotingEnabled(),
          disabled: disabledPivot,
        },
        {
          icon: 'history',
          name: 'History',
          disabled: !!disabledHistory || noSandboxFeature,
          tooltipTitle: noSandboxFeature
            ? 'Your subscription does not include this feature. Please contact your sales representative to add it.'
            : '',
          onClick: () => {
            window.open(
              joinUrl(config.url, `sandbox/all-sandboxing?sha256=${match.params.sha256}`),
              '_blank'
            );
          },
          dataCy: 'sandboxHistoryBtn',
        },
        {
          icon: 'generate-report',
          name: 'Generate Report',
          onClick: () => {
            dispatch(
              openModal('GENERATE_REPORT', {
                type: 'sandbox',
                id: selectedSandbox.id,
              })
            );
          },
          disabled: noReportFeature,
          dataCy: 'scanDownloadPdfBtn',
        },
      ]}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const VerticalBar = styled.hr`
  margin: 24px 0px;
`;

const NoResultTitle = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  color: ${styles.color.red};
`;
const NoResultsCard = ({ provider, vm, sha256, unselected = false }) => {
  const { classes } = useStyles();
  const location = useLocation<{ sandboxId?: string } | undefined>();

  const { sandboxId = '' } = qs.parse(location.search);

  const hasInfo = !!provider;

  return (
    <Card css={[classes.root, (unselected || !isEmpty(sandboxId)) && classes.unselectedSandbox]}>
      {hasInfo && <h1 css={classes.title}>{!!vm ? `${provider}: ${vm}` : provider}</h1>}
      <NoResultTitle>No sandbox results found.</NoResultTitle>
      {!isEmpty(sha256) && <VerticalBar />}
      {!hasInfo && (
        <DetailsContainer>
          <SidebarDetail label='SHA-256' value={sha256} copy={true} monospace={true} />
        </DetailsContainer>
      )}
    </Card>
  );
};

const TooltipContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  & > b {
    font-weight: bolder;
  }
  & > ul {
    list-style: disc;
    list-style-position: inside;
  }
`;

const getSandboxAvailability = (status) =>
  [
    SandboxInstanceStatus.PENDING,
    SandboxInstanceStatus.STARTED,
    SandboxInstanceStatus.SUCCEEDED,
  ].includes(status);

const StarIcon = () => (
  <Icon style={{ color: styles.color.purple, fontSize: '0.8rem' }} className='icon' name='star' />
);

const SCORE_MAP = {
  [SandboxProviders.CAPE]: [
    [
      '0-3',
      'Benign',
      'If the file is benign, likely all trusted files are digitally signed.',
      styles.color.black,
      styles.color.white,
    ],
    [
      '4-6',
      'Suspicious',
      'If the file is Suspicious-Unknown and triggered some signatures that has specific suspicious categories such as: ["network", "encryption", "anti-vm", "anti-analysis", "anti-av", "anti-debug", "anti-emulation", "persistence", "stealth", "discovery", "injection", "generic", "account", "bot", "browser", "allocation", "command"].',
      styles.color.darkYellow,
      styles.color.yellow,
    ],
    [
      '7-9',
      'Malicious',
      'If the file is Malicious-Unknown and triggered some signatures that have specific malicious categories, such as: ["malware", "ransomware", "infostealer", "rat", "trojan", "rootkit", "bootkit", "wiper", "banker", "bypass", "anti-sandbox", "keylogger"].',
      styles.color.darkRed,
      styles.color.lightRed,
    ],
    [
      '10',
      'Malicious',
      'The file is Malicious-Known (The sample is detected by YARA).',
      styles.color.darkRed,
      styles.color.lightRed,
    ],
  ],
  [SandboxProviders.TRIAGE]: [
    ['N/A', 'Not Available', '', styles.color.black, styles.color.white],
    [
      '1',
      'No (potentially) malicious behavior was detected.',
      `The report is incomplete or something went wrong, this could also occur in static reports.`,
      styles.color.black,
      styles.color.white,
    ],
    [
      '2-5',
      'Likely benign',
      `One or more interesting behaviors were detected. The detected actions are interesting enough to be notified about, but are not directly malicious.`,
      styles.color.black,
      styles.color.white,
    ],
    [
      '6-7',
      'Shows suspicious behavior',
      <TooltipContainer>
        <p>
          One or more suspicious actions were detected. The detected actions can be malicious, but
          also have (common) benign uses.
        </p>
        <b>Examples:</b>
        <ul>
          <li>Changing file permissions.</li>
          <li>Anti-VM behavior/trying to detect a VM.</li>
        </ul>
      </TooltipContainer>,
      styles.color.darkYellow,
      styles.color.yellow,
    ],
    [
      '8-9',
      'Likely malicious',
      <TooltipContainer>
        <p>One or more known damaging malware attack patterns were detected.</p>
        <b>Examples:</b>
        <ul>
          <li>The deleting of shadow copies on Windows.</li>
        </ul>
      </TooltipContainer>,
      styles.color.darkRed,
      styles.color.lightRed,
    ],
    [
      '10',
      'Known bad',
      <TooltipContainer>
        <b>Examples:</b>{' '}
        <ul>
          <li>A malware family was detected.</li>
        </ul>
      </TooltipContainer>,
      styles.color.darkRed,
      styles.color.lightRed,
    ],
  ],
};

const ScoreDescription = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SandboxSidebar = () => {
  const { theme, classes } = useStyles();
  const { requests } = useSelector((state: RootState) => state);
  const match = useRouteMatch<{ artifactType: string }>();
  const error = errorSelector(requests, [
    SandboxActionName.GET_SANDBOX_TASK_ID,
    SandboxActionName.GET_SANDBOXES_HASH,
  ]);
  const { topSandbox, selectSandboxId, selectedSandbox, sortedSandboxList } =
    useContext(SandboxSelectionContext);

  const sandboxType = match.params?.artifactType ?? '';
  const location = useLocation<{ sandboxId?: string } | undefined>();
  const { sandboxId = '' } = qs.parse(location.search);

  const { isAuthenticated } = useAuth();

  const latestSandboxes = Object.values(sortedSandboxList)
    .reduce((acc, list) => {
      return acc.concat(list?.[0] ?? []);
    }, [])
    .sort((a, b) => {
      if (a.id === sandboxId) {
        return -1;
      }
      if (b.id === sandboxId) {
        return 1;
      }
      return 0;
    });
  const isLatestSandboxTop = latestSandboxes?.[0]?.id === sandboxId;

  const sandboxList = isLatestSandboxTop
    ? latestSandboxes
    : [].concat(!isEmpty(topSandbox) && !!sandboxId ? topSandbox : [], latestSandboxes);

  if (!isAuthenticated) {
    return <UnAuthSidebarCard />;
  }

  if (!Boolean(error) && !Boolean(sandboxList.length)) {
    return <SidebarLoading />;
  }

  const disabledSandbox = !getSandboxAvailability(selectedSandbox.status);

  const [sandboxTarget = {}] = selectedSandbox?.report?.targets ?? [];
  const sandboxBehavioralScore = sandboxTarget?.score ?? '0';
  const sandboxStaticScore = selectedSandbox?.report?.static?.score ?? '0';
  const sandboxTypes =
    selectedSandbox?.report?.analysis?.tags?.filter((tag) => !tag.includes('family')) ?? [];
  const sandboxTargetName =
    selectedSandbox?.artifact?.filename ??
    selectedSandbox?.config?.target ??
    sandboxTarget?.target ??
    'Unknown';

  const effectiveURL = getEffectiveURL(selectedSandbox?.report, sandboxTargetName);

  return (
    <Container>
      {sandboxList.filter(Boolean).map((item, index) => {
        const hasResults = getSandboxAvailability(item.status);
        const isItemSelected = item.id === selectedSandbox.id;

        const malwareList = item?.report?.malware_family ?? [];

        const provider = item?.config?.provider?.name ?? item?.sandbox;

        const score = (() => {
          switch (SandboxProviders[provider.toUpperCase()]) {
            case SandboxProviders.CAPE: {
              return `${Math.round(item?.report?.malscore ?? 0)}`;
            }
            case SandboxProviders.TRIAGE: {
              return item?.report?.analysis?.score ?? 'N/A';
            }
            default: {
              return '0';
            }
          }
        })();

        const selectedProvider = SandboxProviders[provider.toUpperCase() ?? ''];
        const scoreInfo = SCORE_MAP[selectedProvider].find(([scoreRange, info]) => {
          if (score === 'N/A' && scoreRange.includes('N/A')) {
            return true;
          }
          if (score !== 'N/A' && !scoreRange.includes('N/A')) {
            const [minN = '', maxN = ''] = scoreRange.split('-');
            const isInRange = inRange(
              Number(score),
              0,
              !!maxN ? Number(maxN) + 1 : Number(minN) + 1
            );
            return isInRange;
          }

          return false;
        });

        return (
          <React.Fragment key={item.id}>
            {!hasResults ? (
              <NoResultsCard unselected={true} provider={provider} vm={item.config?.vm?.name} />
            ) : (
              <Card
                onClick={() => {
                  selectSandboxId(item.id);
                }}
                css={[classes.root, !isItemSelected && classes.unselectedSandbox]}
              >
                <div>
                  <div style={{ display: 'flex' }}>
                    {index === 0 && !isLatestSandboxTop && !isEmpty(sandboxId) ? (
                      <></>
                    ) : (
                      <div style={{ marginRight: 18 }}>
                        <StarIcon />
                      </div>
                    )}
                    <h1
                      {...(!isItemSelected ? { style: { fontSize: '1.75rem' } } : {})}
                      css={classes.title}
                    >
                      {!!item?.config?.vm?.name
                        ? `${item.sandbox} : ${item.config.vm.name}`
                        : item.sandbox}
                    </h1>
                  </div>
                  <div
                    {...(!isItemSelected ? { style: { marginBottom: 16 } } : {})}
                    css={classes.subDetail}
                  >
                    <div>{toISODateTime(new Date(item.created))}</div>
                    <>
                      <SidebarDetail
                        center
                        label={isItemSelected ? 'Summary Score' : undefined}
                        value={
                          <span
                            style={{
                              fontWeight: 700,
                              color: theme === 'dark' ? scoreInfo[4] : scoreInfo[3],
                            }}
                            j
                          >
                            {score === 'N/A' ? score : `${score} / 10`}
                          </span>
                        }
                      />
                      {isItemSelected && (
                        <SidebarDetail
                          center
                          label={undefined}
                          value={
                            <Tooltip title={scoreInfo[2]} placement='top'>
                              <ScoreDescription>
                                <span css={classes.summaryDesc}>{scoreInfo[1]}</span>
                                <Icon
                                  style={{ fontSize: '0.5rem' }}
                                  name='info'
                                  title='Score Info'
                                />
                              </ScoreDescription>
                            </Tooltip>
                          }
                        />
                      )}
                    </>
                  </div>
                </div>
                {isItemSelected && <VerticalBar />}
                <DetailsContainer>
                  {sandboxType === 'file' && (
                    <SidebarDetail
                      center
                      label={isItemSelected ? 'Malware Family' : undefined}
                      value={
                        isItemSelected
                          ? malwareList.length > 0
                            ? malwareList
                            : 'Unknown'
                          : malwareList?.[0] ?? 'Unknown'
                      }
                    />
                  )}
                  {isItemSelected && selectedSandbox.sandbox === SandboxProviders.TRIAGE && (
                    <SidebarDetail
                      center
                      label={
                        <Tooltip
                          title={`This score represents the likelihood of the Artifact having been used in a malicious manner previously.`}
                          placement='top'
                        >
                          <ScoreDescription>
                            <span>Static Analysis Score</span>
                            <Icon
                              style={{ fontSize: '0.5rem' }}
                              name='info'
                              title='Static Summary'
                            />
                          </ScoreDescription>
                        </Tooltip>
                      }
                      value={`${sandboxStaticScore} / 10`}
                    />
                  )}
                  {isItemSelected && selectedSandbox.sandbox === SandboxProviders.TRIAGE && (
                    <SidebarDetail
                      center
                      label={
                        <Tooltip
                          title={`This score represents the browser, host and network behaviour during a sandboxing session.`}
                          placement='top'
                        >
                          <ScoreDescription>
                            <span>Behavioral Score</span>
                            <Icon
                              style={{ fontSize: '0.5rem' }}
                              name='info'
                              title='Behavioral Summary'
                            />
                          </ScoreDescription>
                        </Tooltip>
                      }
                      value={`${sandboxBehavioralScore} / 10`}
                    />
                  )}
                  {isItemSelected && (
                    <Tooltip title={sandboxTargetName} placement='top'>
                      <div>
                        <SidebarDetail
                          copy
                          center
                          label={
                            sandboxType === 'file' ? 'Filename' : effectiveURL ? 'Target' : 'URL'
                          }
                          value={sandboxTargetName}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {isItemSelected && effectiveURL && (
                    <SidebarDetail
                      copy
                      copyText={effectiveURL}
                      info={effectiveURL.length > 50 ? effectiveURL : undefined}
                      center
                      label={
                        <Tooltip
                          title='The URL of the primary webpage after redirection'
                          placement='top'
                        >
                          <ScoreDescription>
                            <span>Effective URL</span>
                            <Icon
                              style={{ fontSize: '0.5rem' }}
                              name='info'
                              title='The URL of the primary webpage after redirection'
                            />
                          </ScoreDescription>
                        </Tooltip>
                      }
                      value={
                        effectiveURL.length > 50 ? effectiveURL.slice(0, 50) + '...' : effectiveURL
                      }
                    />
                  )}
                  {isItemSelected && provider === SandboxProviders.TRIAGE && (
                    <SidebarDetail
                      center
                      label={'Type'}
                      value={sandboxTypes.length > 0 ? sandboxTypes : 'Unknown'}
                    />
                  )}
                </DetailsContainer>
                {isItemSelected && (
                  <>
                    <VerticalBar />
                    <DetailsContainer>
                      <SidebarDetail
                        label='Sandbox Id'
                        value={item.id}
                        copy={true}
                        monospace={true}
                      />
                      <SidebarDetail
                        label='SHA-256'
                        value={item.sha256}
                        copy={true}
                        monospace={true}
                      />
                    </DetailsContainer>
                  </>
                )}
              </Card>
            )}
          </React.Fragment>
        );
      })}
      <SandboxSidebarActions
        selectedSandbox={selectedSandbox}
        disabledPivot={disabledSandbox}
        disabledShare={disabledSandbox}
      />
    </Container>
  );
};

const SandboxPanel = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation<{ sandboxId?: string } | undefined>();
  const match = useRouteMatch<{ artifactType: string; sha256: string }>();
  const { requests } = useSelector((state: RootState) => state);
  const error = errorSelector(requests, [SandboxActionName.GET_SANDBOXES_HASH]);
  const isLoading = loadingSelector(requests, [
    SandboxActionName.GET_SANDBOXES_HASH,
    SandboxActionName.GET_SANDBOX_TASK_ID,
  ]);
  const { active: isPivotActive } = usePivoting();

  const { isAuthenticated } = useAuth();

  const { selectedSandbox } = useContext(SandboxSelectionContext);
  const isVideoTab = location.pathname.endsWith('video');

  const sha256 = match.params?.sha256 ?? '';
  const sandboxType = match.params?.artifactType ?? '';

  const { data: httpData, hasPresentValues: hasHttpData } = useMemo(
    () => getHttpData(selectedSandbox?.report ?? {}, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { analysisData, hasPresentValues: hasAnalysisData } = useMemo(
    () => getAnalysisData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { data: networkData, hasPresentValues: hasNetworkData } = useMemo(
    () => getNetworkData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { extractedConfigData, hasPresentValues: hasExtractedConfigData } = useMemo(
    () => getExtractedConfigData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { data: droppedFilesData, hasData: hasDroppedFilesData } = useMemo(
    () => getDroppedFilesData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );

  const recordingVideo = useMemo(
    () => selectedSandbox?.sandboxArtifacts?.find((item) => item?.type === 'recording') ?? {},
    [selectedSandbox.sandboxArtifacts]
  );

  const screenshots = useMemo(
    () => selectedSandbox?.sandboxArtifacts?.filter((item) => item?.type === 'screenshot') ?? {},
    [selectedSandbox.sandboxArtifacts]
  );

  const isVideoExpired = useMemo(
    () =>
      !isEmpty(selectedSandbox) &&
      isDateBefore(new Date(selectedSandbox.created), new Date(2024, 5, 30)),
    [selectedSandbox]
  );

  const urls = Object.assign(
    {
      home: parseSandboxURL(sha256, { sandboxType }),
      network: parseSandboxURL(sha256, { sandboxType, section: 'network' }),
      fileData: parseSandboxURL(sha256, { sandboxType, section: 'file-data' }),
      extractedConfig: parseSandboxURL(sha256, { sandboxType, section: 'extracted-config' }),
      dropped: parseSandboxURL(sha256, { sandboxType, section: 'dropped' }),
      json: parseSandboxURL(sha256, { sandboxType, section: 'json' }),
      analysis: parseSandboxURL(sha256, { sandboxType, section: 'analysis' }),
      httpRequests: parseSandboxURL(sha256, { sandboxType, section: 'http' }),
      download: parseSandboxURL(sha256, { sandboxType, section: `download` }),
    },
    selectedSandbox?.sandbox === SandboxProviders.TRIAGE && {
      video: parseSandboxURL(sha256, { sandboxType, section: `video` }),
    },
    selectedSandbox?.sandbox === SandboxProviders.CAPE && {
      screenshots: parseSandboxURL(sha256, { sandboxType, section: `screenshots` }),
    }
  );

  const isActiveTab = (tab) => location.pathname.includes(`/${tab}`);

  const tabs = [
    {
      value: urls.extractedConfig,
      label: 'Extracted Config',
      dataTestId: 'sandboxExtractedConfig',
      disabled: !hasExtractedConfigData && !isLoading,
      active: isActiveTab('extracted-config'),
    },
    {
      value: urls.dropped,
      label: 'Dropped Files',
      dataTestId: 'sandboxDropped',
      disabled: !hasDroppedFilesData && !isLoading,
      active: isActiveTab('dropped'),
    },
    {
      value: urls.network,
      label: 'Network',
      dataTestId: 'sandboxNetwork',
      disabled: !hasNetworkData && !isLoading,
      active: isActiveTab('network'),
    },
    selectedSandbox?.sandbox === SandboxProviders.TRIAGE && {
      value: urls.httpRequests,
      label: 'HTTP Requests',
      dataTestId: 'httpRequests',
      disabled: !hasHttpData && !isLoading,
      active: isActiveTab('http'),
    },
    {
      value: urls.analysis,
      label: 'Analysis',
      dataTestId: 'analysis',
      disabled: !hasAnalysisData && !isLoading,
      active: isActiveTab('analysis'),
    },
    {
      value: urls.json,
      label: 'JSON',
      dataTestId: 'sandboxJson',
      disabled: isPivotActive || (!selectedSandbox?.report && !isLoading),
      disabledText: isPivotActive ? 'Pivot Active' : undefined,
      active: isActiveTab('json'),
    },
    {
      value: urls.download,
      label: 'Download Results',
      dataTestId: 'sandboxDownload',
      disabled: isPivotActive || (!selectedSandbox?.report && !isLoading),
      disabledText: isPivotActive ? 'Pivot Active' : undefined,
      active: isActiveTab('download'),
    },
    selectedSandbox?.sandbox === SandboxProviders.TRIAGE && {
      value: urls.video,
      label: 'Video Playback',
      dataTestId: 'sandboxVideo',
      disabledText:
        (isPivotActive && 'Pivot Activate') ||
        (isVideoExpired
          ? 'The recording cannot be played here, but is still downloadable in the Downloads tab.'
          : undefined),
      disabled:
        (isPivotActive && 'Pivot Activate') ||
        (isVideoExpired
          ? isVideoExpired
          : !!recordingVideo &&
            ((!selectedSandbox?.report && !isLoading) || !recordingVideo.instanceId)),
      active: isActiveTab('video'),
    },
    selectedSandbox?.sandbox === SandboxProviders.CAPE && {
      value: urls.screenshots,
      label: 'Screenshots',
      dataTestId: 'sandboxImages',
      disabledText: isPivotActive ? 'Pivot Active' : undefined,
      disabled: isPivotActive || (isEmpty(screenshots) && !selectedSandbox?.report && !isLoading),
      active: isActiveTab('screenshots'),
    },
  ].filter(Boolean);

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => {
    history.push(`${pathname}${location?.search ?? ''}`);
  };

  const checkLoadingState = useCallback(
    (Component) => {
      if (isLoading) {
        return <PanelLoader />;
      }

      return !isAuthenticated ? (
        <UnAuthSandboxPanel />
      ) : getSandboxAvailability(selectedSandbox.status) ? (
        Component
      ) : (
        <EmptySandboxing
          type='custom'
          title='No Sandbox results for this artifact.'
          infoText='Click the Re-Sandboxing button to trigger a sandbox detonation for this artifact.'
        />
      );
    },
    [isAuthenticated, isLoading, selectedSandbox.status]
  );

  const networkError = Object.assign(
    !!error && getSandboxAvailability(selectedSandbox.status) ? {} : { error }
  );

  const NetworkTabComponent = useCallback(
    () =>
      checkLoadingState(
        <NetworkTab
          status={selectedSandbox.status}
          provider={selectedSandbox.sandbox}
          data={networkData}
          {...networkError}
        />
      ),
    [networkError, checkLoadingState, selectedSandbox.status, selectedSandbox.sandbox, networkData]
  );

  const HttpRequestsComponent = useCallback(
    () => checkLoadingState(<HttpRequestsTab provider={selectedSandbox.sandbox} data={httpData} />),
    [httpData, selectedSandbox.sandbox, checkLoadingState]
  );

  const JSONTabComponent = useCallback(
    () => checkLoadingState(<JSONTab data={selectedSandbox?.report} />),
    [selectedSandbox?.report, checkLoadingState]
  );

  const AnalysisTabComponent = useCallback(
    () =>
      checkLoadingState(
        <AnalysisTab provider={selectedSandbox.sandbox} dataMapped={analysisData} />
      ),
    [analysisData, checkLoadingState, selectedSandbox.sandbox]
  );

  const DroppedTabComponent = useCallback(
    () =>
      checkLoadingState(<DroppedTab provider={selectedSandbox.sandbox} data={droppedFilesData} />),
    [droppedFilesData, checkLoadingState, selectedSandbox.sandbox]
  );

  const ExtractedConfigTabComponent = useCallback(
    () =>
      checkLoadingState(
        <ExtractedConfigTab provider={selectedSandbox.sandbox} data={extractedConfigData} />
      ),
    [checkLoadingState, extractedConfigData, selectedSandbox.sandbox]
  );

  const ArtifactDownloadTabComponent = useCallback(
    () =>
      checkLoadingState(
        <ArtifactDownloadTab
          sandboxedArtifactId={selectedSandbox.instanceId}
          sha256={sha256}
          error={error}
          status={selectedSandbox.status}
          data={selectedSandbox.sandboxArtifacts}
        />
      ),
    [
      selectedSandbox.instanceId,
      error,
      checkLoadingState,
      sha256,
      selectedSandbox.status,
      selectedSandbox.sandboxArtifacts,
    ]
  );

  const VideoTabComponent = useCallback(
    () => checkLoadingState(<VideoTab sandboxedArtifactId={recordingVideo.instanceId} />),
    [recordingVideo, checkLoadingState]
  );

  const ScreenshotTabComponent = useCallback(
    () =>
      checkLoadingState(<ScreenshotTab artifactIds={screenshots.map((item) => item.instanceId)} />),
    [screenshots, checkLoadingState]
  );

  if (error?.message === 'invalid_sandbox' && isAuthenticated) {
    return (
      <Card style={{ padding: '5rem' }}>
        <ErrorPageContent
          heading='Sandbox not found'
          text={`The sandbox with the ID ${sha256} was not found.`}
        >
          <Button
            data-cy='scanSearchRetry'
            style={{ fontSize: '2.4rem' }}
            color='primary'
            variant='contained'
            onClick={() => window.location.replace('/sandbox')}
          >
            Go to All Sandboxing
          </Button>
        </ErrorPageContent>
      </Card>
    );
  }

  if (!selectedSandbox?.sandbox || isLoading) {
    return null;
  }

  return (
    <Card>
      <PaginatedTabs
        tabs={tabs}
        maxTabs={5}
        minTabs={3}
        indicatorColor='primary'
        value={location.pathname}
        onChange={_handleChange}
      >
        <a
          css={[classes.link, !isAuthenticated && classes.disabledElement]}
          className='a'
          href={parseScanPageURL(sha256, {
            type: sandboxType,
            fallbackId: selectedSandbox.instanceId,
          })}
          onClick={(e) => {
            window.open(
              parseScanPageURL(sha256, {
                type: sandboxType,
                fallbackId: selectedSandbox.instanceId,
              }),
              '_blank'
            );
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          Latest Scan Results
          <Icon disabled={!isAuthenticated} className='icon' name='open-view' />
        </a>
      </PaginatedTabs>
      <Panel>
        <Switch>
          <Route exact path={urls.extractedConfig} render={ExtractedConfigTabComponent} />
          <Route exact path={urls.dropped} render={DroppedTabComponent} />
          <Route exact path={urls.network} render={NetworkTabComponent} />
          <Route exact path={urls.analysis} render={AnalysisTabComponent} />
          <Route exact path={urls.httpRequests} render={HttpRequestsComponent} />
          <Route exact path={urls.json} render={JSONTabComponent} />
          <Route exact path={urls.download} render={ArtifactDownloadTabComponent} />
          {selectedSandbox?.sandbox === SandboxProviders.TRIAGE && !isVideoExpired && (
            <Route exact path={urls.video} render={VideoTabComponent} />
          )}
          {selectedSandbox?.sandbox === SandboxProviders.CAPE && (
            <Route exact path={urls.video} render={ScreenshotTabComponent} />
          )}
          <Redirect exact from={urls.home} to={`${urls.network}${location?.search}`} />
          {isVideoTab ? (
            <Redirect
              to={
                isVideoExpired
                  ? `${urls.download}${location?.search}`
                  : `${urls.network}${location?.search}`
              }
            />
          ) : (
            <Redirect to='/404' />
          )}
        </Switch>
      </Panel>
    </Card>
  );
};

const SandboxSelectionContext = createContext<{
  selectedSandbox: Sandbox<SandboxReport>;
  selectSandboxId: () => void;
}>({
  selectedSandbox: {},
  selectSandboxId: () => null,
});

const SandboxDetails = () => {
  const { active: isPivotActive } = usePivoting();
  const dispatch = useDispatch<Dispatch>();
  const match = useRouteMatch<{ artifactType: string; sha256: string }>();
  const location = useLocation<{ sandboxId?: string } | undefined>();
  const [selectedSandboxId, selectSandboxId] = useState('');
  const sha256: string = match.params?.sha256;
  const { sandboxId = '' } = qs.parse(location.search);

  const sandboxList = useSelector((state: RootState) => state.sandbox.list);
  const sortedSandboxList = getSortedSandboxList(sandboxList, sandboxId);
  const initialSandbox = !isEmpty(sandboxId)
    ? sandboxList.find((item) => item.id === sandboxId) ?? {}
    : Object.values(sortedSandboxList)?.flat()?.[0] ?? {};

  const selectedSandbox = !!selectedSandboxId
    ? sandboxList.find((item) => item.id === selectedSandboxId) ?? initialSandbox
    : initialSandbox;

  useEffect(() => {
    if (!!sandboxId) {
      dispatch(getSandboxTaskByHashAndId(sha256, sandboxId));
    }

    dispatch(getLatestSandboxesByHash(sha256));
  }, [dispatch, sandboxId, sha256]);

  const _refreshContext = useCallback(async (ctx?: ContextAccount) => {
    await dispatch(getAccountContext(ctx));
    // TODO a race condition with UserProvider cause this to not work if not called twice :/
    await dispatch(getAccountContext(ctx));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSandbox?.contextAccount?.endsWith('-private')) {
      _refreshContext(readContextAccount(selectedSandbox.contextAccount));
    }
  }, [selectedSandbox?.contextAccount, _refreshContext]);

  return (
    <SandboxSelectionContext.Provider
      value={{ topSandbox: initialSandbox, sortedSandboxList, selectedSandbox, selectSandboxId }}
    >
      {isPivotActive && <PivotingFilter />}
      <PageSidebarLayout renderSidebar={SandboxSidebar} renderPanel={SandboxPanel} />
    </SandboxSelectionContext.Provider>
  );
};

const useStyles = makeStyles({
  base: {
    link: {
      marginRight: '1.5rem',
      marginTop: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
      fontWeight: 600,
      fontSize: '1.5rem',
      '& .icon': {
        fontSize: '0.7rem',
      },
    },
    root: {
      padding: styles.spacing.sm,
      position: 'relative',
      border: `4px solid ${styles.color.purple}`,
    },
    unselectedSandbox: {
      opacity: 0.7,
      cursor: 'pointer',
      border: 'none',
    },
    title: {
      textAlign: 'center',
      textTransform: 'capitalize',
      fontSize: '2rem',
      fontWeight: styles.font.weight.bold,
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: styles.spacing.tiny,
    },
    subDetail: {
      marginTop: 16,
      gap: 16,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1.4rem',
    },
    disabledElement: {
      cursor: 'not-allowed',
      color: `${styles.color.grey} !important`,
    },
  },
  light: {
    summaryDesc: {
      color: styles.color.black,
    },
  },
  dark: {
    summaryDesc: {
      color: styles.color.white,
    },
  },
});
export default SandboxDetails;
